import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import {
  util,
  preventDefaultPropagation,
  paginate,
  formatMinuteSecondSOL
} from 'utils/kbUtil'
import { ACTIVITY_STATUS_OBJ } from 'app/constants'
import {
  KBLoadingContainer,
  KBStatisticNumber,
  KBAvatar,
  KBPopover,
  KBPagination,
  KBTableWithoutData,
  KBPopoverTop,
  KBToReportCenter,
  KBTable
} from 'components'
import * as selectors from 'app/selectors'
import { getUserInfoUrl } from 'utils/kbUrl'
import moment from 'moment'
import TableSearch from 'components/v2/TableSearch'
import { successState, apiCallFailure } from 'app/actions'
import _ from 'lodash'
import { useStatus } from './constants'
import ExportButton from './ExportButton'

var CouponOrders = React.createClass({
  displayName: 'CouponOrders',

  getInitialState() {
    return {
      filters: {
        status: ''
      },
      pagination: null,
      page: 1,
      loading: true,
      tableColumns: [
        {
          title: '姓名',
          key: 'name',
          render: record => {
            return (
              <Link className="color-link" to={getUserInfoUrl(record.user.id)}>
                {record.user.name}
              </Link>
            )
          }
        },
        {
          title: '电话',
          key: 'phone',
          render: record => {
            return record.user.secure_phone_num
          }
        },
        {
          title: '领券时间',
          key: 'time',
          render: record => {
            return moment.unix(record.created_at).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          title: '使用状态',
          key: 'status',
          render: record => {
            return record.coupon_template_status
          }
        },
        {
          title: '用券时间',
          key: 'useTime',
          render: record => {
            return record.used_at
              ? moment.unix(record.used_at).format('YYYY-MM-DD HH:mm:ss')
              : ''
          }
        },
        {
          title: '分店名称',
          key: 'location',
          render: record => {
            return _.get(record, 'sales_invoice.location_name')
          }
        },
        {
          title: '会议室名称',
          key: 'meetingRoom',
          render: record => {
            return _.get(record, 'sales_invoice.reservation_area_name')
          }
        },
        {
          title: '账单编号',
          key: 'order',
          render: record => {
            return <Link className="color-link" to={this.getInvoiceInfoUrl(_.get(record, 'sales_invoice.id'))}>
              {_.get(record, 'sales_invoice.serial_number')}
            </Link>
          }
        }
      ],
      dataList: [],
      couponInfo: {}
    }
  },

  componentDidMount() {
    this.mounted = true
    this.getCouponInfo()
  },
  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.location.query.page) {
      this.setState({ page: nextProps.location.query.page }, () => {
        this.loadData(nextProps.location.query.page)
      })
      return
    }
  },

  getCouponInfo() {
    const { couponId } = this.props
    api.getCouponTemplateInfo(couponId).then(
      json => {
        const data = json.json
        this.setState({
          couponInfo: data
        })
        if (data.coupon_type === 'desk') {
          this.setState({
            tableColumns: this.state.tableColumns.filter(
              i => i.key !== 'meetingRoom'
            )
          })
        }
        this.loadData()
      },
      error => {
        this.props.apiCallFailureAction(error)
      }
    )
  },

  getInvoiceInfoUrl(invoiceId) {
    const { locationId } = this.props
    if(locationId) {
      return `/admin/locations/${locationId}/invoices/location_orders/${invoiceId}?type=`
    } else {
      return `/admin/invoices/membership_orders/${invoiceId}?type=membership`
    }
  },

  loadData(page) {
    const { couponId, locationId } = this.props
    let params = Object.assign(
      {
        location_id: locationId || undefined
      },
      { id: couponId, per_page: 1, page: page || 1 },
      this.state.filters
    )

    api
      .getCouponTemplateOrder(couponId, params)
      .then(
        ({ json, response }) => {
          if (!this.mounted) {
            return
          }
          let pagination = paginate(response)
          this.setState({
            pagination,
            dataList: json
          })
        },
        error => {
          this.props.apiCallFailureAction({
            message: error.message
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  reportActivitySignUp() {
    const { couponId } = this.props
    api.reportActivitySignUp({ couponId }).then(
      res => {
        return KBPopoverTop.show(
          <KBToReportCenter
            title="Excel导出"
            message="Excel已成功导出，可以前往报表中心下载"
          />
        )
      },
      err => {
        console.log(err)
      }
    )
  },
  SignInActivity() {
    const { couponId } = this.props
  },

  getPhone(phone) {
    if (!phone) return ''
    return phone.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters }, () => {
      this.loadData()
    })
  },

  render() {
    const { loading, pagination, dataList, couponInfo } = this.state
    const { couponId, locationId } = this.props
    return (
      <div className="nav-section-container f-right">
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>领券明细</span>
          </div>
          <div className="f-right" style={{ display: 'flex' }}>
            <ExportButton couponId={couponId} />
          </div>
        </header>
        <KBLoadingContainer loading={loading}>
          <div className="activity-chart" style={{ textAlign: 'center' }}>
            <div className="chart-box">
              <KBStatisticNumber
                number={couponInfo.total_number || 0}
                name="总券数"
              />
            </div>
            <div className="chart-box">
              <KBStatisticNumber
                number={couponInfo.user_coupon_templates_count || 0}
                name="领券数"
              />
            </div>
            <div className="chart-box">
              <KBStatisticNumber
                number={couponInfo.user_coupon_templates_used_count || 0}
                name="用券数"
              />
            </div>
          </div>
          <div style={{ padding: '30px 30px' }}>
            <TableSearch
              label="使用状态"
              value={this.state.filters.status}
              options={useStatus}
              onChange={val => this._setFilter({ status: val || undefined })}
            />
            <KBTable columns={this.state.tableColumns} dataSource={dataList} />
            <KBPagination
              pagination={pagination}
              template={
                locationId
                  ? `/admin/locations/${locationId}/coupon/${couponId}/orders?page=#PAGE#`
                  : `/admin/coupon/${couponId}/orders?page=#PAGE#`
              }
            />
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const { couponId, id: locationId } = params

  return {
    locationId,
    couponId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

CouponOrders = connect(mapStateToProps, mapDispatchToProps)(CouponOrders)

export default CouponOrders
